// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-en-js": () => import("./../../../src/pages/404.en.js" /* webpackChunkName: "component---src-pages-404-en-js" */),
  "component---src-pages-404-es-js": () => import("./../../../src/pages/404.es.js" /* webpackChunkName: "component---src-pages-404-es-js" */),
  "component---src-pages-blog-en-js": () => import("./../../../src/pages/blog.en.js" /* webpackChunkName: "component---src-pages-blog-en-js" */),
  "component---src-pages-blog-es-js": () => import("./../../../src/pages/blog.es.js" /* webpackChunkName: "component---src-pages-blog-es-js" */),
  "component---src-pages-cursos-en-js": () => import("./../../../src/pages/cursos.en.js" /* webpackChunkName: "component---src-pages-cursos-en-js" */),
  "component---src-pages-cursos-es-js": () => import("./../../../src/pages/cursos.es.js" /* webpackChunkName: "component---src-pages-cursos-es-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-es-js": () => import("./../../../src/pages/index.es.js" /* webpackChunkName: "component---src-pages-index-es-js" */),
  "component---src-pages-privacy-en-js": () => import("./../../../src/pages/privacy.en.js" /* webpackChunkName: "component---src-pages-privacy-en-js" */),
  "component---src-pages-privacy-es-js": () => import("./../../../src/pages/privacy.es.js" /* webpackChunkName: "component---src-pages-privacy-es-js" */),
  "component---src-pages-utilities-en-js": () => import("./../../../src/pages/utilities.en.js" /* webpackChunkName: "component---src-pages-utilities-en-js" */),
  "component---src-pages-utilities-es-js": () => import("./../../../src/pages/utilities.es.js" /* webpackChunkName: "component---src-pages-utilities-es-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

